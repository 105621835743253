.concerts {
  margin: 3rem 10rem;
  text-align: center;
  &__title {
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: .1rem;
    text-transform: uppercase;
    margin-bottom: 3rem; }

  @include desktop {
    margin: 2rem 5rem; }
  @include tablet {
    margin: 2rem 4rem; }
  @include mobile {
    margin: 2rem 1rem; }

  &__month {
    &-title {
      font-size: 2rem;
      font-weight: 600;
      letter-spacing: .1rem;
      text-transform: uppercase;
      margin-bottom: 1rem; } } }

.concert {
  text-align: left;
  margin: 1.5rem 0;
  padding: 1rem;
  border: 1px solid $c-grey;
  &:hover {
    border: 1px solid $c-white; }

  font-size: 1rem;


  @include flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include tablet {
 }    // justify-content: space-around

  > * {
    margin-bottom: 0.5rem; }

  &__date-container {
    // min-width: 12rem
    width: 10rem; }
  &__location-container {
    width: 21rem;
    // min-width: 19rem
 }    // max-width: 21rem
  &__date-container, &__location-container {
    @include flex; }
  &__date-container {
    min-width: 16rem; }
  &__date-icon, &__location-icon {
    height: 2.4rem;
    margin-right: .5rem;
    flex-shrink: 0; }

  &__date-dmy, &__location-city, &__price-amount, &__btn {
    // font-size: .9rem
    font-size: 1.3rem;
    font-weight: bold; }

  &__date-time, &__location-place {
    // font-size: .9rem
    font-size: 1rem; }

  &__price {
    margin-left: .5rem;
    width: 8rem; }
  &__price-amount {
 }    // margin-left: .5rem

  &__btn {
    cursor: pointer;
    @include tablet {
      width: 100%;
      margin-top: .5rem; }
    text-align: center;
    padding: 1rem;
    border: 1px solid $c-grey;
    &:hover {
      background: $c-white;
      border: 1px solid $c-white;
      color: $c-black-logo; } } }
