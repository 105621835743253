.music {
  padding: 4rem 0;
  text-align: center;

  &__title {
    font-size: 1.9rem;
    letter-spacing: .1rem;
    text-transform: uppercase;
    margin-bottom: 3rem; }

  &__platforms {
    @include center;

    @include mobile {
      display: block; }

    > * {
      &:not(:first-child) {
        margin-left: 1rem;
        @include mobile {
          margin: 0;
          margin-top: 1rem; } } } }

  &__yandex {
    iframe {
      border-radius: 10px; } }

  &__apple, &__yandex {
    @include flex;
    flex-direction: column;
    iframe {
      min-width: 285px;
      @include mobile {
        width: 70vw; } }
    svg {
      margin-top: .5rem;
      width: 11rem;
      height: 5rem; }
    a:hover {
      border-bottom: 1px solid transparent; } } }
