.videos {
  background: $c-light;
  padding: 5rem 0;
  text-align: center;

  &__link {
    margin-top: 2rem;
    font-size: 1.5rem;
    color: $c-black-logo;
    @include flex;
    justify-content: center;
    flex-wrap: wrap;
    img {
      height: 4rem; } } }

.carousel {
  &__carousel {
    @include flex;
    justify-content: safe center;
    margin: 0 auto;
    margin-top: .5rem;
    width: 85vw;
    height: 11rem;
    @include tablet {
      justify-content: flex-start;
      width: 90vw; }
    @include mobile {
      width: 95vw;
      height: 8rem; }
    overflow: hidden;
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 7px; }
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.2k);
      -webkit-border-radius: 10px;
      border-radius: 10px; }
    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.55); } }

  &__v-small {
    height: 10rem;
    cursor: pointer;
    position: relative;
    &:first-child {
      // margin-left: auto
 }      // margin: 0 auto
    &:not(:first-child) {
      margin-left: 1rem; }
    @include mobile {
      height: 7rem; } }

  &__v-img {
    height: 100%;
    border-radius: 4px; }

  &__v-overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .3);
    border-radius: 4px;
    &:hover {
      background: none; } } }

.youtube-embed-container {
  width: 60vw;
  margin: 0 auto;
  @include tablet {
    width: 80vw; }
  @include mobile {
    width: 90vw; } }

.youtube-embed {
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
  border-radius: 4px;
  height: 100px;

  &__iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    object-fit: fill;

    &::before {
      content: "";
      display: block;
      padding-bottom: calc(100% / (16 / 9)); } } }
