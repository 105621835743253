.hamburger {
  z-index: 300;
  cursor: pointer;

  position: fixed;
  left: 1rem;
  top: 1.2rem;
  transform-origin: 1px;

  path {
    width: 2rem;
    height: .25rem;
    transition: all 0.3s linear;
    transform-origin: 1px;
    stroke: white;
    &:first-child {
      transform: rotate(0); }

    &:nth-child(2) {
      opacity: 1;
      transform: translateX(0); }

    &:nth-child(3) {
      transform: rotate(0); } }

  &--trans {
    path {
      transform-origin: 1px;
      stroke: black;
      &:first-child {
        transform: rotate(45deg) translateY(-10px); }

      &:nth-child(2) {
        opacity: 0;
        transform: translateX(20px); }

      &:nth-child(3) {
        transform: rotate(-45deg) translateY(5px) translateX(-1px); } } } }

