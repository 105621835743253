.about {
  background: $c-light;
  color: $c-black-logo;
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 4rem;

  &__title {
    margin-bottom: 1.5rem;
    font-size: 1.9rem;
    letter-spacing: .1rem;
    text-transform: uppercase; }

  &__container {
    margin: 0 auto;
    display: flex;
    max-width: 850px;
    width: 80vw;

    @include mobile {
      flex-direction: column-reverse;
      align-items: center; } }

  &__image {
    height: 400px;
    border-radius: 10px;

    @include mobile {
      margin-top: 1rem;
      width: 100%;
      height: auto; } }

  &__text {
    // flex: 1
    text-align: left;
    margin-left: 1rem;

    @include mobile {
      margin: 0; }

    &-title {
      font-size: 1.5rem;
      margin-bottom: 1.2rem;
      font-weight: bold; } } }
