.landing {
  height: 100vh;
  @include center;
  position: relative;
  @include mobile;

  &__heading {
    z-index: 80; }
  &__title {
    position: relative;
    z-index: 80;
    font-size: 7vw;
    text-align: left;
    letter-spacing: .1rem;
    font-weight: 400;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, .5);
    text-transform: uppercase;
    @include tablet {
      font-size: 8vw; }
    @include mobile {
      font-size: 10vw;
      margin-left: 1rem; } }

  &__video-wrapper {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    // z-index: -1
    text-align: center; }

  &__video, &__video-small {
    min-height: 100%;
    min-width: 100%;

    max-width: 130%;
    max-height: 130%;

    @include tablet {
      max-width: 175%;
      max-height: 175%;
      transform: translateX(-15%); } }

  &__video {
    @include mobile {
      display: none; }
    z-index: -10;
    &--show {
      z-index: 100;
      @include mobile {
        display: none; } } }

  &__img {
    height: 100%;
    &--hide {
      display: none;
      @include mobile {
        display: none; } }

    @include mobile {
      display: none; } }

  &__video-small {
    position: absolute;
    display: none;
    @include mobile {
      display: inline-block; }
    max-width: 400%;
    max-height: 400%;
    transform: translateX(-45%);
    z-index: -10;
    &--show {
      z-index: 50; } }

  &__img-small {
    display: none;
    @include mobile {
      display: block;
      min-height: 100%;
      max-height: 160%;
      min-width: 100%;
      max-width: 170%;
      transform: translateY(-10%); }
    @include mobile-small {
      display: block;
      height: 100vh;
      transform: translateY(0); }
    &--hide {
      display: none;
      @include mobile {
        display: none; }
      @include mobile-small {
        display: none; } } } }
