@mixin flex {
  display: flex;
  align-items: center; }

@mixin center {
  @include flex;
  justify-content: center; }

@mixin mobile {
  @media screen and (max-width: 768px) {
    @content; } }

@mixin mobile-small {
  @media screen and (max-width: 450px) {
    @content; } }

@mixin tablet {
  @media screen and (max-width: 1024px) {
    @content; } }

@mixin desktop {
  @media screen and (max-width: 1216px) {
    @content; } }
