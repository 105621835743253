$c-black-spotify: #191414;
$c-black-logo: #03030a;
$c-black-logo-op: #03030a40;
$c-blue-tuk: #6b45d3;
$c-blue-link: #848ddf;
$c-white: white;
$c-light: #e8f7f2;
$c-grey: grey;
$c-grey-light: #cad6d3;
$c-grey-dark: #161616;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

body {
  background: $c-black-spotify;
  color: white;
  font-family: "Montserrat", "Roboto", sans-serif; }

html, body, #root, .App {
  height: 100%; }

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column; }
a {
  text-decoration: none;
  border-bottom: .5px solid transparent;
  padding: .25rem 0;
  outline: non;
  color: inherit;
  color: $c-grey-light;
  &:active {
    text-decoration: none;
    outline: none; }
  &:hover {
    color: $c-light;
    // text-decoration: underline $c-light
    border-bottom: .5px solid $c-light; } }

.content {
 }  // margin-top: 4rem

@import 'abstract/all';
@import 'components/all';
@import 'pages/all';
