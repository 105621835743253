.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 200;
  padding: 4rem;
  height: 100%;
  width: 20rem;
  background: $c-white;
  color: $c-black-logo;

  transform: translateX(-100%);
  transition: all .3s linear;

  &__link {
    text-transform: uppercase;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    a {
      color: inherit;
      &:hover {
        border-bottom: .5px solid $c-black-logo; } } }

  &--open {
    transform: translateX(0%); }

  @include mobile {
    width: 100%; } }
