.header {
  z-index: 100;
  position: fixed;
  top: 0;
  width: 100%;
  height: 3rem;
  background-color: $c-black-logo;
  opacity: .96;
  padding: 2rem;
  // border-bottom: .5px solid $c-light

  @include flex;
  justify-content: space-between;

  @include tablet {
    padding: 2rem 1rem; }

  &__left {
    @include flex;
    margin-left: 1.5rem;

    @include tablet {
      margin-left: 2rem; } }

  &__menu {
    @include flex;
    > * {
      margin-left: 1rem;
      text-transform: uppercase;
      @include tablet {
        margin-left: .25rem; } }
    @include mobile {
      display: none; } }

  &__links {
    @include flex;
    @include mobile {
      display: none; } }

  &__link {
    border: none !important;
    svg {
      width: 3.4rem;
      height: 1.1rem; }

    &-spotify svg {
      height: 1.2rem; }

    &-apple, &-vk, &-spotify, &-tiktok {
      path {
        fill: $c-grey-light; }
      &:hover {
        path {
          fill: $c-light; } } }
    &-youtube, &-instagram {
      path {
        stroke: $c-grey-light; }
      &:hover {
        path {
          stroke: $c-light; } } } }


  &__name {
    border: none !important;
    font-size: 1.8rem;
    text-transform: uppercase;
    letter-spacing: .1rem;

    &--hide {
      display: none; }

    @include tablet {
      font-size: 1.4rem; }
    @include mobile {
      font-size: 1.2rem; } }


  &--fading {
    // background-color: $c-black-logo-op
    background: none; } }
